<template>
    <div>
		<b-modal ref="modalContractModifRapide" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("modal.contract.modification_rapide") }}
				</template>
			</template>

			<div class="col-12">
				<div class="form-group">
					<label class="mr-2" for="sort-switch">
						{{ $t('monte.attestation_saillie') }}
					</label>
					<b-form-checkbox
						v-model="avenant_as_bool"
						switch
						id="sort-switch"
						class="d-inline"
					>
					</b-form-checkbox>
				</div>

				<div class="form-group">
					<div class="row col-12">
						<label class="mr-2" for="sort-switch">
							{{ $t('monte.declaration_naissance') }}
						</label>
						<b-form-checkbox
							v-model="avenant_dn_bool"
							switch
							id="sort"
							class="d-inline"
						>
						</b-form-checkbox>
						<div class="col-12">
							<e-datepicker v-if="avenant_dn_bool" v-model="avenant_dn" id="avenant_dn" ></e-datepicker>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label for="skip" class="col-form-label">{{ $t("monte.contract_note") }}</label>
					<textarea id="commentaire" class="form-control" rows="3" v-model="contract_note"></textarea>
				</div>

				<div class="form-group">
					<label for="skip" class="col-form-label">{{ $t("monte.commentaire") }}</label>
					<textarea id="commentaire" class="form-control" rows="3" v-model="commentaire"></textarea>
				</div>

				<div class="form-group">
					<label for="skip" class="col-form-label">{{ $t("monte.numero_dps") }}</label>
					<input type="text" class="form-control" v-model="avenant_dps">
				</div>

				<div class="row">
					<div class="col-6">
						<div class="form-group">
							<label for="validation_date">{{ $t('monte.validation_date') }}</label>
							<e-datepicker v-model="validation_date" :class="{ 'is-invalid': signatureError && signatureError.indexOf('validation_date') > -1 }"></e-datepicker>
						</div>
					</div>

					<div class="col-6">
						<div class="form-group">
							<label for="signature_date">{{ $t('monte.signature_date') }}</label>
							<e-datepicker v-model="signature_date"></e-datepicker>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label for="type_contrat">{{ $t('monte.type_contrat') }} *</label>
					<e-select
						v-model="type_contrat"
						id="type_contrat"
						track-by=""
						:placeholder="$t('monte.type_contrat')"
						:selectedLabel="$t('global.selected_label')"
						:options="list_type_contrat"
						:allow-empty="false"
						:show-labels="false"
						:class="{ 'is-invalid': signatureError && signatureError.indexOf('type_contrat') > -1 }"
					>
						<template slot="option" slot-scope="{ option }">{{ $t('monte.'+option) }}</template>
						<template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.'+option) }}</template>
					</e-select>
				</div>

				<div class="form-group" v-if="type_contrat == 'sur_parts' || type_contrat == 'sur_pool'">
					<label for="saillie">{{ $t('monte.numero_saillie') }}</label>
					<e-select
						v-model="saillie"
						id="saillie"
						track-by=""
						:placeholder="$t('monte.numero_saillie')"
						:selectedLabel="$t('global.selected_label')"
						:options="syndic_saillie_formatted"
						:allow-empty="false"
						:show-labels="false"
						:loading="saillie_loading"
						:class="{ 'is-invalid': signatureError && signatureError.indexOf('saillie') > -1 }"
					>
						<template slot="option" slot-scope="{ option }">{{ option.value }}</template>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.value }}</template>
					</e-select>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("global.modifier") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import _isEmpty from 'lodash/isEmpty'
import Contract from '@/mixins/Contract.js'
import Syndic from '@/mixins/Syndic.js'

export default {
	name: "ModalContractModifRapide",
	mixins: [Contract, Syndic],
	data () {
		return {
			processing: false,
			contract_id: null,
			contract_note: '',
			commentaire: '',
			avenant_id: null,
			avenant_dn: null,
			avenant_dn_bool: false,
			avenant_dps: '',
			avenant_as_bool: false,
			validation_date: null,
			signature_date: null,
			avenant_status: null,
			list_type_contrat : [],
			signatureError: [],
			type_contrat: null,
			saillie_loading: false,
			stallion_id: null,
			season_id: null,
			saillie: null,

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.list_type_contrat = ['sur_parts', 'sur_pool', 'etalonnier']

			this.saillie_loading = true
			let syndic_saillie = await this.loadHorseSyndicBySeason(this.stallion_id, this.season_id)
			this.saillie_loading = false
			if(syndic_saillie.length == 0) {
				this.list_type_contrat = ['etalonnier']
			}
		},
		openModal(infos_contract, infos_avenant) {
			if(this.contract_id !== infos_contract.contract_id) {
				this.contract_id = infos_contract.contract_id
				this.contract_note = infos_contract.contract_note
				this.avenant_id = infos_avenant.avenant_id
				this.avenant_dn = infos_avenant.avenant_dn ? new Date(infos_avenant.avenant_dn) : null
				this.avenant_dn_bool = infos_avenant.avenant_dn_bool ? true : false
				this.avenant_dps = infos_avenant.avenant_dps
				this.avenant_as_bool = infos_avenant.avenant_as_bool ? true : false
				this.validation_date = infos_avenant.avenant_date_validation ? new Date(infos_avenant.avenant_date_validation) : null
				this.signature_date = infos_avenant.avenant_date_signature ? new Date(infos_avenant.avenant_date_signature) : null
				this.avenant_status = infos_avenant.avenant_status
				this.stallion_id = infos_contract.contractconfig_horse
				this.season_id = infos_contract.contractconfig_season
				this.commentaire = infos_avenant.avenant_comment

				// infos_avenant.avenant_type_contrat est le type traduit donc il faut récupérer le code pour le e-select
				const list_type_contrat = ['sur_parts', 'sur_pool', 'etalonnier']
				for(let i in list_type_contrat) {
					if(this.getTrad('monte.' + list_type_contrat[i]) == infos_avenant.avenant_type_contrat) {
						this.type_contrat = list_type_contrat[i]
					}
				}
			}

			this.$refs.modalContractModifRapide.show()
		},
		async checkForm() {
			this.processing = true
			this.signatureError = []

			if(this.validation_date && this.type_contrat == null) {
				this.signatureError.push("type_contrat")
			}

			if(!this.validation_date && this.type_contrat) {
				this.signatureError.push("validation_date")
				this.type_contrat = null
			}

			if(this.signature_date && !this.validation_date) {
				this.signatureError.push("validation_date")
			}

			if(this.signatureError.length > 0) {
				this.processing = false
				return false
			}

			if(!this.avenant_dn_bool) {
				this.avenant_dn = null
			}

			let infos_contract = {
				contract_id: this.contract_id,
				contract_note: this.contract_note
			}
			let infos_avenant = {
				avenant_id: this.avenant_id, 
				avenant_dn: this.avenant_dn, 
				avenant_dn_bool: this.avenant_dn_bool, 
				avenant_dps: this.avenant_dps, 
				avenant_as_bool: this.avenant_as_bool, 
				validation_date: this.validation_date, 
				signature_date: this.signature_date,
				avenant_comment: this.commentaire
			}

			this.fastEditContract(infos_contract, infos_avenant)
				.then(async () => {
					this.processing = false
					this.$refs.modalContractModifRapide.hide()
				})

			await this.$sync.runOptionalWhishlist(['contract'])

			let contract_id = this.$sync.replaceWithReplicated('contract', this.contract_id)

			await this.signatureAvenant(this.avenant_id, this.signature_date, this.validation_date, this.type_contrat, this.avenant_dps)
			let status = 2
			if(this.signature_date != null)
			{
				status = 3
			}
			await this.editStatusAvenant(this.avenant_id, status)

			await this.freeSaillieContractv2(contract_id)
			if(this.saillie != null && (this.type_contrat == "sur_parts" || this.type_contrat == "sur_pool"))
			{
				await this.savePartContract(contract_id, this.saillie.saillie_id)
			}
			
			await this.$sync.force(true, true)

			await this.$sync.force(true, true)

			this.processing = false
		}
	},
	computed: {
		syndic_saillie_formatted() {
			if(_isEmpty(this.syndic_saillie)) return []

			let type_contrat = "syndicpart_is_perso"
			if(this.type_contrat == "sur_pool")
			{
				type_contrat = "syndicpart_is_pool"
			}

			let res = []
			this.syndic_saillie.forEach(syndic => {
				syndic.syndic_saillies.forEach(saillie => {
					if(saillie.saillie_part[type_contrat] == 1) {
						if(saillie.saillie_part.syndicpart_tiers.length === 0 || (saillie.saillie_contract !== null && saillie.saillie_contract !== this.contract_id)) {
							return
						}
						
						let tiers_tab = []
						saillie.saillie_part.syndicpart_tiers.forEach(tiers => {
							tiers_tab.push(tiers.tierpart_tier.tiers_rs)
						})

						const tab_current = {
							value: `${syndic.syndic_label} # ${saillie.saillie_number} / ${tiers_tab.join(' - ')} / ${this.$t(saillie.saillie_part.syndicpart_invoice_type.invoicetype_labeltrad)}`,
							invoice_type: saillie.saillie_part.syndicpart_invoice_type.invoicetype_code,
							saillie_id: saillie.saillie_id,
							type_contrat: type_contrat,
							syndicat: this.syndic_saillie.syndic_tiersyndicat,
							tiers: {
									tiers_id:saillie.saillie_part.syndicpart_tiers[0].tierpart_tier.tiers_id,
									tiers_rs:saillie.saillie_part.syndicpart_tiers[0].tierpart_tier.tiers_rs
								}
						}

						res.push(tab_current)

						if(saillie.saillie_contract == this.contract_id) {
							this.has_part = true
							this.saillie = tab_current
						}
					}
				})
			})
			return res
		},
		show_resa() {
			return this.avenant.cmep && this.type_monte && this.type_monte.type == 'iac' && this.contract_config && window.navigator.onLine
		}
	},
	watch:{
		saillie (val){
			if(val != null)
			{
				if(val.type_contrat == "syndicpart_is_pool")
				{
					this.change_emetteur = {
						tiers_id: val.syndicat.tiers_id,
						tiers_rs: val.syndicat.tiers_rs
					} 
				}
				else
				{
					if(val.invoice_type === 'PLCDE'){
						this.change_emetteur = {
								tiers_id: val.tiers.tiers_id,
								tiers_rs: "Pour le compte de " + val.tiers.tiers_rs
							}
					}
					else if(val.invoice_type === 'PMOI'){
						//Je fais rien
					}
					else {
						this.change_emetteur = val.tiers
					}
				}
			}
		},
		type_contrat(val){
			this.saillie = null
		}
	},
}
</script>